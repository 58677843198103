import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./ForgotPassword.css";
import LockIcon from "../Assets/Group.png";
import eyeOpenIcon from "../Assets/Show.png";
import eyeClosedIcon from "../Assets/Hide.png";
import green from "../Assets/green.png";
import red from "../Assets/red.png";
import Danger from "../Assets/Danger Circle.png";

const passwordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, "Must be at least 8 characters long")
    .matches(/\d/, "Must contain at least one numeric character")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Must contain at least one special character (example: !, #, @)"
    )
    .required("New password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const NewPassword = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const togglePasswordVisibility = (field) => {
    if (field === "newPassword") {
      setPasswordVisible(!passwordVisible);
    } else {
      setConfirmPasswordVisible(!confirmPasswordVisible);
    }
  };

  return (
    <div className="container d-flex justify-content-center align-items-center min-vh-100">
      <div
        className="card text-center p-4 shadow"
        style={{
          maxWidth: "500px",
          width: "100%",
          borderRadius: "20px",
          border: "1px solid #1F9AA24D",
        }}
      >
        <div className="card-body">
          <img
            src={LockIcon}
            alt="Lock Icon"
            className="mb-3"
            style={{ maxWidth: "100px" }}
          />
          <h3 className="card-title">Create New Password</h3>
          <p className="card-text">Create new password below.</p>

          <Formik
            initialValues={{ newPassword: "", confirmPassword: "" }}
            validationSchema={passwordSchema}
            onSubmit={(values, { setSubmitting }) => {
              console.log(values);
              setSubmitting(false);
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className=" position-relative">
                  <Field
                    name="newPassword"
                    type={passwordVisible ? "text" : "password"}
                    placeholder="New Password*"
                    className={`form-input ${
                      touched.newPassword && errors.newPassword
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <img
                    src={passwordVisible ? eyeOpenIcon : eyeClosedIcon}
                    alt="Toggle password visibility"
                    className="password-toggle-icon"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "34%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      height: "19px",
                      width: "23px",
                    }}
                    onClick={() => togglePasswordVisibility("newPassword")}
                  />
                </div>
                {touched.newPassword && (
                  <div className="password-requirements mb-3 text-start">
                    <p className="requirement">
                      {!errors.newPassword ||
                      !errors.newPassword.includes("8 characters") ? (
                        <img
                          src={red}
                          style={{
                            width: "17px",
                            height: "17px",
                            marginRight: "8px",
                            marginLeft: "8px",
                          }}
                          alt="Logo"
                        />
                      ) : (
                        <img
                          src={green}
                          style={{
                            width: "17px",
                            height: "17px",
                            marginRight: "8px",
                            marginLeft: "8px",
                          }}
                          alt="Logo"
                        />
                      )}{" "}
                      <span style={{ fontSize: "12px" }}>
                        Must be at least 8 characters long
                      </span>
                    </p>
                    <p className="requirement">
                      {!errors.newPassword ||
                      !errors.newPassword.includes("numeric") ? (
                        <img
                          src={red}
                          style={{
                            width: "17px",
                            height: "17px",
                            marginRight: "8px",
                            marginLeft: "8px",
                          }}
                          alt="Logo"
                        />
                      ) : (
                        <img
                          src={green}
                          style={{
                            width: "17px",
                            height: "17px",
                            marginRight: "8px",
                            marginLeft: "8px",
                          }}
                          alt="Logo"
                        />
                      )}{" "}
                      <span style={{ fontSize: "12px" }}>
                        Must contain at least one numeric character
                      </span>
                    </p>
                    <p className="requirement">
                      {!errors.newPassword ||
                      !errors.newPassword.includes("special character") ? (
                        <img
                          src={red}
                          style={{
                            width: "17px",
                            height: "17px",
                            marginRight: "8px",
                            marginLeft: "8px",
                          }}
                          alt="Logo"
                        />
                      ) : (
                        <img
                          src={green}
                          style={{
                            width: "17px",
                            height: "17px",
                            marginRight: "8px",
                            marginLeft: "8px",
                          }}
                          alt="Logo"
                        />
                      )}{" "}
                      <span style={{ fontSize: "12px" }}>
                        Must contain at least one special character (example: !,
                        #, @)
                      </span>
                    </p>
                  </div>
                )}

                <div className=" position-relative">
                  <Field
                    name="confirmPassword"
                    type={confirmPasswordVisible ? "text" : "password"}
                    placeholder="Confirm Password*"
                    className={`form-input ${
                      touched.confirmPassword && errors.confirmPassword
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  <img
                    src={confirmPasswordVisible ? eyeOpenIcon : eyeClosedIcon}
                    alt="Toggle confirm password visibility"
                    className="password-toggle-icon"
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "34%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                      height: "19px",
                      width: "23px",
                    }}
                    onClick={() => togglePasswordVisibility("confirmPassword")}
                  />
                </div>
                {errors.confirmPassword && (
                  <div className="error-message-top">
                    {errors.confirmPassword && (
                      <div style={{ display: "flex", padding: "8px" }}>
                        <span className="error-icon">
                          <img
                            src={Danger}
                            style={{
                              width: "17px",
                              height: "17px",
                              marginRight: "8px",
                              marginLeft: "8px",
                            }}
                            alt="Logo"
                          />
                        </span>
                        <span style={{ fontSize: "14px", paddingTop: "3px" }}>
                          {" "}
                          {errors.confirmPassword}
                        </span>
                      </div>
                    )}
                  </div>
                )}
                <button type="submit" className="btn btn-primary btn-lg w-100">
                  Reset Password
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
