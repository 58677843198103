import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./LoginPage.css";
import GroupImg from "../Assets/Group3.png";
import Danger from "../Assets/Danger Circle.png";

const validationSchema = Yup.object().shape({
  accountId: Yup.string()
    .matches(/^\d{4}$/, "Account Id must be exactly 4 digits")
    .required("Account Id is required"),
  userName: Yup.string().required("Please enter the valid User Name"),
  password: Yup.string().required("Password is required"),
});

const LoginPage = () => {
  const [formData, setFormData] = useState({
    accountId: "",
    userName: "",
    password: "",
  });

  return (
    <div className="login-page d-flex">
      <div className="left-section d-flex flex-column align-items-center justify-content-center">
        <div className="text-center">
          <div className="left-banner">
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={GroupImg}
                style={{ width: "65px", marginRight: "8px" }}
                alt="Logo"
              />
              <h2>Logo</h2>
            </span>

            <h1>Welcome to Legal Tasks Management and Monitoring Software</h1>
            <p>
              Your centralized hub for managing legal tasks, tracking case
              progress, and optimizing workflows.
            </p>
          </div>
        </div>
      </div>


      <div className="right-section d-flex align-items-center justify-content-center">
        <div className="login-form">
          <div className="form-box">
            <h2 className="text-center">Log In</h2>
            <p className="text-center">Enter your login details below.</p>

            <Formik
              initialValues={{ accountId: "", userName: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                setFormData(values);
                setSubmitting(false);
                console.log("Form Submitted", values);
              }}
            >
              {({ errors,touched, isSubmitting }) => (
                <Form>
                  {(errors.accountId || errors.userName || errors.password) && (
                    <div className="error-message-top">
                      {errors.accountId  && (
                        <div><span className="error-icon"><img
                        src={Danger}
                        style={{ width: "17px",
                          height: "17px",
                          marginRight: "8px",
                          marginLeft: "8px" }}
                        alt="Logo"
                      /></span><span style={{fontSize:"14px"}}> {errors.accountId}</span></div>
                      )}
                      {errors.userName && (
                        <div><span className="error-icon"><img
                        src={Danger}
                        style={{ width: "17px",
                          height: "17px",
                          marginRight: "8px",
                          marginLeft: "8px" }}
                        alt="Logo"
                      /></span><span style={{fontSize:"14px"}}>{errors.userName}</span> </div>
                      )}
                      {errors.password  && (
                        <div><span className="error-icon"><img
                        src={Danger}
                        style={{ width: "17px",
                          height: "17px",
                          marginRight: "8px",
                          marginLeft: "8px" }}
                        alt="Logo"
                      /></span><span style={{fontSize:"14px"}}> {errors.password}</span></div>
                      )}
                    </div>
                  )}
                  <div className="form-group">
                    <Field
                      name="accountId"
                      type="text"
                      placeholder="Account Id*"
                      className={`form-input ${touched.accountId && errors.accountId ? "input-error" : ""}`}
                      maxLength="4"
                    />
                  </div>

                  <div className="form-group">
                    <Field
                      name="userName"
                      type="text"
                      placeholder="Username*"
                      className={`form-input ${touched.userName && errors.userName ? "input-error" : ""}`}
                    />
                  </div>

                  <div className="form-group">
                    <Field
                      name="password"
                      type="password"
                      placeholder="Password*"
                      className={`form-input ${touched.password && errors.password ? "input-error" : ""}`}
                    />
                  </div>

                  <div className="d-flex justify-content-between align-items-center mb-3" style={{ paddingTop: "14px" }}>
                    <div>
                      <input type="checkbox" id="rememberMe" />
                      <label htmlFor="rememberMe" style={{ paddingLeft: "5px" }}>Remember me</label>
                    </div>
                    <a href="/forgotPassword" className="forgot-password">Forget Password?</a>
                  </div>

                  <button
                    type="submit"
                    className="loginBtn btn btn-success btn-block"
                    disabled={isSubmitting}
                  >
                    Log In
                  </button>
                </Form>
              )}
            </Formik>

            <p className="text-center mt-3">
              Don't have an account? <a style={{ color: "#70D560" }} href="/signup">Sign Up</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
